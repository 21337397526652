import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Card } from "react-bootstrap";

const PostTemplate = ({ data: { post, posts, site } }) => {
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${site.siteMetadata.siteUrl}/blog`,
					id: `${site.siteMetadata.siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
					id: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={post.seoFields.metaTitle}
				description={post.seoFields.metaDescription}
				language="en"
				openGraph={{
					type: "article",
					url: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
					title: post.seoFields.opengraphTitle,
					description: post.seoFields.opengraphDescription,
					images: [
						{
							url: `${post.seoFields.image?.link}`,
							width: `${post.seoFields.image?.localFile.childImageSharp.original.width}`,
							height: `${post.seoFields.image?.localFile.childImageSharp.original.height}`,
							alt: `${post.seoFields.image?.altText}`,
						},
					],
				}}
			/>
			<section>
				<div className=" pt-5 pt-lg-7 ">
					<Container>
						<Row className="justify-content-center">
							<Col lg={10} xl={8}>
								<h1 className="  mb-4  ">{post.title}</h1>
								<p>
									{post.dateGmt
										? new Date(post.dateGmt).toLocaleDateString("en-GB", {
												day: "2-digit", // numeric day of the month
												month: "short", // abbreviated month
												year: "numeric", // full numeric year
										  })
										: ""}
								</p>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="px-lg-6 py-5 pb-lg-7" fluid>
					<Row className="justify-content-center">
						<Col>
							<div
								style={{ borderRadius: "24px", overflow: "hidden" }}
								className="position-relative"
							>
								<GatsbyImage
									style={{ maxHeight: "700px", borderRadius: "24px" }}
									className=" w-100 "
									image={
										post.blogFields.featuredImage.localFile.childImageSharp
											.gatsbyImageData
									}
									alt={post.blogFields.featuredImage?.altText}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="bg-med-grey py-5 py-lg-7">
				<Container>
					<Row className="justify-content-center">
						<Col
							className="position-relative blog-post-content-new"
							lg={10}
							xl={8}
						>
							{parse(post.content)}
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-9">
				<Container>
					<Row className="g-5 h-100 ">
						{posts.nodes.map((post) => (
							<Col style={{ minHeight: "100%" }} md={6} xl={4}>
								<Link
									className="text-decoration-none"
									to={`/blog/${post.slug}`}
								>
									<div className="gradient-border-blog h-100">
										<Card className="h-100 position-relative p-2 blog-card">
											<Card.Img
												style={{ maxHeight: "254px", borderRadius: "14px" }}
												as={GatsbyImage}
												variant="top"
												image={
													post.blogFields.featuredImage.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={post.blogFields.featuredImage.altText}
											/>
											<Card.Body className="ps-0 pb-3 pt-4">
												<Card.Title className="text-heading-color fs-6">
													{post.title}
												</Card.Title>
												<Card.Text
													style={{ fontSize: "90%" }}
													className="text-heading-color"
												>
													{post.dateGmt
														? new Date(post.dateGmt).toLocaleDateString(
																"en-GB",
																{
																	day: "2-digit", // numeric day of the month
																	month: "short", // abbreviated month
																	year: "numeric", // full numeric year
																}
														  )
														: ""}
													<span className="mx-2">•</span>{" "}
													{post.blogFields.numberOfMinRead} min read
												</Card.Text>
											</Card.Body>
										</Card>
									</div>
								</Link>
							</Col>
						))}
					</Row>
				</Container>
			</section>
			<section className="pb-5 pb-lg-7">
				<Container>
					<Row>
						<Col className="text-center">
							<h2 className="mb-4">Looking for insurance?</h2>
							<p className="mb-5">
								Ensure you get the most out of your insurance. Find your perfect
								insurance policy with Quotestar.
							</p>
							<Button
								size="lg"
								className=" gradient-button me-md-4 w-100 w-md-auto px-4 py-3 text-uppercase get-quotes-btn"
								as={Link}
								to="/insurance-categories"
							>
								Find Insurance
							</Button>
							{/* <Button
								variant="outline-primary"
								size="lg"
								className=" mt-3 mt-md-0  w-100 w-md-auto px-4 py-3   text-uppercase"
								as={Link}
								to="/contact-page"
							>
								Contact us
							</Button> */}
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default PostTemplate;

export const pageQuery = graphql`
	query BlogPostById($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			slug
			content
			dateGmt
			title
			seoFields {
				metaTitle
				opengraphTitle
				opengraphDescription
				metaDescription
				image {
					altText
					link
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
					}
				}
			}

			blogFields {
				featuredImage {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}

		posts: allWpPost(
			sort: { order: DESC, fields: dateGmt }

			filter: { id: { ne: $id }, title: { ne: "Website Images" } }
		) {
			nodes {
				dateGmt
				title
				excerpt
				slug
				blogFields {
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
